import React from 'react';

export default ({ validate, label, name, className = '', error, errors = [], ...props }) => {
	return (
		<div className={`form-field ${className}`}>
			{label && (
				<label className="form-label" htmlFor={name}>
					{label}
				</label>
			)}
			<input
				name={name}
				placeholder={label}
				{...props}
				className={`form-input ${errors[name] ? 'error' : ''}`}
                ref={validate}
			/>
            {errors[name] && <div className="form-error">{error}</div>}
		</div>
	);
};
