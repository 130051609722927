import React from "react"
import MyLink from "../components/link"
import Navigation from "../includes/navigation.js"
import Logo from "../assets/img/logo.svg"

export default function Header() {
    return (
        <header className="header">
            <div className="container">
                <MyLink to="/" title="Artificial Studio" label="Artificial Studio">
                    <Logo width={185} height={54} alt="Artificial Studio" className="logo logo-main" />
                </MyLink>
                <Navigation />
            </div>
        </header>
    )
}