import React, { useState } from "react"
import { useForm } from "react-hook-form"
import InputText from "../fields/text"
import InputTextarea from "../fields/textarea"
import axios from "axios"

export default function ContactForm({ className='', title='Send us a Message', description='We\'d love to hear from you' }) {
    const formType = 'Contact';
    const formUrl = 'https://traktion.app/forms/send/';
    const successMsg = '<strong>Your message was sent successfully.</strong><br />We\'ll respond as soon as possible.';
    let isContact = (typeof window !== 'undefined' && window.location.pathname == '/contact/') ? true : false;

    const { register, handleSubmit, errors } = useForm(); // watch
    const [ sending, setSending ] = useState(false);
    const [ success, setSuccess ] = useState(false);

    function onSubmit(data) {
        setSending(true);
        data.type = formType;
        axios.post(formUrl, data).then(res => {
            setSending(false);
            if(res.data.success === true) {
                setSuccess(true);
                if(isContact) window.scrollTo(0,0);
            } else {
                alert('Something went wrong, please email us - info@artificial.studio');
                console.log(res);
            }
        });
    }

    return (
        <form encType="multipart/formdata" method="POST" onSubmit={handleSubmit(onSubmit)} className={className}>
            {success &&
                <>
                    <h2>Thank you!</h2>
                    <p className="cl-primary" dangerouslySetInnerHTML={{__html: successMsg}}></p>
                </>
            }

            {!success &&
                <>
                    {title !== '' &&<h2>{title}</h2>}
                    <p className="cl-primary" dangerouslySetInnerHTML={{__html: description}}></p>

                    <InputText
                        className="w-full"
                        label="Name"
                        name="first_name"
                        error="Please enter your name"
                        validate={register({ required: true })}
                        errors={errors}
                    />

                    <InputText
                        className="w-full"
                        label="Email"
                        name="email"
                        error="Please enter a valid email address"
                        validate={register({
                            required: true,
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                            }
                        })}
                        errors={errors}
                    />

                    <InputText
                        className="w-full"
                        label="Contact number"
                        name="contact_number"
                        errors={errors}
                        validate={register()}
                    />

                    <InputTextarea
                        className="w-full"
                        label="Message"
                        name="message"
                        error="Please enter a message"
                        validate={register({ required: true })}
                        errors={errors}
                    />

                    <div className="form-field">
                        <button disabled={sending} type="submit" className={`btn btn-lg btn-primary mt-4 ${sending ? 'disabled': ''}`}>
                            {sending && <>Sending...</>}
                            {!sending && <>Submit</>}
                        </button>
                    </div>
                </>
            }
        </form>
    );
}