import React, { useState, useContext } from "react"
import "typeface-epilogue"
import "../assets/css/tailwind.css"
import "../assets/styl/main.styl"
import SEO from "../includes/seo.js"
import Header from "../includes/header.js"
import Footer from "../includes/footer.js"
import ContactModal from "../components/contactModal"
import DreamProject from "../components/dreamProject"
import {ModalContext, ModalProvider, ModalConsumer} from "../components/modalContext"

export default function Layout({ children }) {
    const isBrowser = typeof window !== `undefined`;
    const [scrolled, setScrolled] = useState(false);
    const [hasListener, setHasListener] = useState(false);
    const modalContext = useContext(ModalContext);

    function handleScroll() {
        if(isBrowser) {
            if(window.scrollY > 400) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        }
    }

    if(isBrowser && !hasListener) {
        setHasListener(true);
        window.addEventListener('scroll', handleScroll);
    }

    return (
        <ModalProvider>
            <ModalConsumer>
                {context =>
                    <div className={`layout ${context.modalOpen === true ? 'modal-is-open' : ''} ${scrolled === true ? 'scrolled' : ''}`}>
                        <SEO/>
                        <Header/>
                        <article className="content">
                            {children}
                        </article>
                        <DreamProject/>
                        <Footer/>
                        <ContactModal/>
                    </div>
                }
            </ModalConsumer>
            <div className="radial-gradient"></div>
        </ModalProvider>
    )
}