import React, { useContext } from "react"
import MyLink from "../components/link"
import { ModalContext } from "../components/modalContext"

export default function DreamProject() {
    const modalContext = useContext(ModalContext);
    let path = typeof window !== 'undefined' ? window.location.pathname : '';
    let exclude = [
        '/get-a-quote/'
    ];
    let show = !(exclude.indexOf(path) > -1);

    return (
        <div>
            {show &&
                <>
                    <div className="component bg-blue clear-both">
                        <div className="container">
                            <h2 className="text-center mb-4">Are you ready to build your dream project?<span className="symbol symbol-dark"><span></span></span></h2>
                            <p className="text-center text-larger cl-dark">
                                <strong>We're ready to receive your brief and discuss your ideas!</strong><br />
                                <a onClick={(e) => { e.preventDefault(); modalContext.openModal('contact-modal'); }} className="cl-dark underline" href="#">Get in touch</a> if you want to schedule a chat or <MyLink to="/get-a-quote/" className="cl-dark underline">get a quote</MyLink> from us.
                            </p>
                            <p className="text-center mt-12">
                                <button onClick={() => modalContext.openModal('contact-modal')} className="btn btn-lg btn-dark">Send us a Message</button>
                                <MyLink to="/get-a-quote/" className="btn btn-dark btn-lg">Get a Quote</MyLink>
                            </p>
                        </div>
                    </div>
                    {/*
                    <div className="component bg-blue clear-both">
                        <div className="container">
                            <h2 className="text-center mb-4">Are you ready to build your dream project?<span className="symbol symbol-dark"><span></span></span></h2>
                            <p className="text-center text-larger cl-dark">
                                Answer a few questions and you will receive an <strong>immediate budget range</strong> for your project.<br/>
                                We will review your requirements and respond with an official quote in <strong>1 business day</strong>.
                            </p>
                            <p className="text-center mt-12"><MyLink to="/get-a-quote/" className="btn btn-dark btn-lg">Let’s Get Started!</MyLink></p>
                        </div>
                    </div>
                    */}
                </>
            }
        </div>
    )
}