import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import gsap, { Expo } from "gsap"

export default function MyLink({ children, to, className, label='', onClick }) {
    const animTime = 0.35;
    const animEase = Expo.easeOut;

    function entryAnimation(node) {
        return gsap.from(node, {
            delay: animTime,
            duration: animTime,
            right: '-50%',
            opacity: 0,
            ease: animEase,
        });
    }

    function exitAnimation(exit, node) {
        var tl = gsap.timeline();
        tl.to(node, {
            duration: animTime,
            left: '-50%',
            opacity: 0,
            ease: animEase
        });
    }

    return <TransitionLink
            aria-label={label}
            to={to}
            onClick={onClick}
            exit={{
                length: animTime,
                trigger: ({ exit, e, node }) => exitAnimation(exit, node),
            }}
            entry={{
                delay: 0,
                length: animTime,
                trigger: ({ entry, node }) => entryAnimation(node)
            }}
            className={className}
    >
        {children}
    </TransitionLink>
}