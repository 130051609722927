import React, { Component } from 'react';

const ModalContext = React.createContext();
const ModalConsumer = ModalContext.Consumer;

class ModalProvider extends Component {
    state = {
        modalOpen: false,
        activeModal: ''
    };

    closeModal = () => {
        this.setState(state => ({
            ...state,
            modalOpen: false
        }));
    }

    openModal = (val) => {
        this.setState(state => ({
            modalOpen: true,
            activeModal: val
        }));
    };

    render() {
        return (
            <ModalContext.Provider
                value={{ modalOpen: this.state.modalOpen, activeModal: this.state.activeModal, closeModal: this.closeModal, openModal: this.openModal}}
            >
                {this.props.children}
            </ModalContext.Provider>
        );
    }
}

export { ModalProvider, ModalContext, ModalConsumer };